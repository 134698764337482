<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-10-08 15:18:04
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="fgtrgs">
    <heade></heade>
    <div class="content">
      <div class="from">
        <div class="titled">
          <p class="p_1">忘记密码</p>
          <p class="p_2">
            已有账号立即
            <router-link class="log_a" to="/primarylog">登录</router-link>
          </p>
        </div>
        <!-- <div class="from_a">
          <ul class="ul_left">
            <li>企业管理号</li>
            <li>手机号</li>
            <li>验证码</li>
            <li>输入新密码</li>
            <li>确认新密码</li>
          </ul>
          <ul class="ul_right">
            <li>
              <input type="text" v-model="phone1" @keyup="keyu" placeholder="请输入统一社会信用代码">
            </li>
            <li>
              <input type="text" v-model="phone" @keyup="keyu" placeholder="请输入手机号">
            </li>
            <li class="li_c">
              <input type="text" v-model="codea" @keyup="keyu" placeholder="请输入验证码" class="coded">
              <p class="code" v-show="show" @click="code">获取验证码</p>
              <p class="code" v-show="!show">{{sj}}s</p>
            </li>
            <li>
              <input
                :type="pwd"
                @keyup="keyu"
                @focus="focu_2"
                @blur="blu_2"
                placeholder="请输入密码"
                v-model="password"
              >
            </li>
            <li>
              <input type="password" v-model="password_2"
              @keyup="keyu"
                @focus="focu_3"
                @blur="blu_3" placeholder="请重复上面密码">
            </li>
            <li>
              <div class="log_b" v-if="checked_no">确认</div>
            <div class="log" @click="forget" v-if="!checked_no">确认</div>
            </li>
          </ul>
          <img class="img_a" src="../assets/Stroke.png">
          <img class="img_b" src="../assets/Stroken.png">
          <img class="img_c" src="../assets/Stroken.png">
          <div class="eye_box">
            <img class="eye" src="../assets/eye.png" v-if="eyeb" alt @click="eyea">
            <img class="eye" src="../assets/eye-no.png" v-if="eyeb_no" alt @click="eyea_no">
          </div>
          <div
            class="regb animated bounceInRight"
            v-if="pwd_2"
            style="color:#e6a23c;font-size:0.08rem;"
            >请输入数字与字母或特殊字符的6-20位密码</div>
          <div
            class="regb animated bounceInRight"
            v-if="pwd_2_ok"
            style="color:green;font-size:0.08rem;"
            >密码格式正确</div>
        </div> -->
        <div class="from_a">
          <ul>
            <li>
              <p>企业管理号</p>
              <input type="text" v-model="phone1" @keyup="keyu" placeholder="请输入统一社会信用代码">
            </li>
            <li>
              <p>手机号</p>
              <input type="text" v-model="phone" @keyup="keyu" placeholder="请输入手机号">
              <img src="../assets/Stroke.png">
            </li>
            <li>
              <p>验证码</p>
              <input type="text" v-model="codea" @keyup="keyu" placeholder="请输入验证码">
              <p class="code" @click="show?code():wuyong()">{{show?'获取验证码':sj+'s'}}</p>
            </li>
            <li>
              <p>输入新密码</p>
              <input :type="pwd" @keyup="keyu" @focus="focu_2" @blur="blu_2" v-model="password" placeholder="请输入密码">
              <img src="../assets/Stroken.png">
              <img class="eye" :src="eyeb?require('../assets/eye.png'):require('../assets/eye-no.png')" alt @click="eyea">
            </li>
            <li>
              <p>确认新密码</p>
              <input type="password" placeholder="请重复上面密码" @keyup="keyu"
                @focus="focu_3"
                @blur="blu_3" v-model="password_2">
              <img src="../assets/Stroken.png">
            </li>
            <li>
              <p></p>
              <p :style="checked_no?'':'background: #8d52e3;'" @click="checked_no?wuyong():forget()">确认</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <foote></foote>
  </div>
</template>

<script>
import heade from '../components/Heade'
import foote from '../components/Foote'
import { forget_ent_acct_pwd, ent_sms } from '../api/api.js'
export default {
  name: 'fgtrgs',
  data () {
    return {
      sj: '',
      show: true,
      eyeb_no: true,
      eyeb: false,
      pwd: 'password',
      password: '',
      pwd_2: false,
      pwd_2_ok: false,
      pwd_3: false,
      pwd_3_ok: false,
      checked_no: true,
      phone: '',
      codea: '',
      password_2: '',
      phone1: ''
    }
  },
  components: { heade, foote },
  methods: {
    //   倒计时
    dao () {
      this.show = false
      const TIME_COUNT = 60
      if (!this.timer) {
        this.sj = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.sj > 0 && this.sj <= TIME_COUNT) {
            this.sj--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    //
    home () {
      this.$router.push('/')
    },
    //   获取验证码
    code () {
      if (!/^1[3456789]\d{9}$/.test(this.phone) && this.phone1.length != 18) {
        this.$message({
          message: '手机号码或企业管理号有误，请重填',
          type: 'warning'
        })
      } else {
        this.sms_a()
      }
    },
    // 验证码接口
    sms_a () {
      ent_sms({
        data: { mobile: this.phone, ent_acct: this.phone1 }
      }).then(res => {
        if (res.data.code == 10000) {
          this.$message({
            message: '今日以超过最大值',
            type: 'warning'
          })
        } else if (res.data.code == 10001) {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
          this.dao()
        } else if (res.data.code == 10002) {
          this.$message({
            message: '短信发送失败',
            type: 'error'
          })
        }
      })
    },
    eyea () {
      this.eyeb = !this.eyeb
      this.pwd = this.eyeb ? 'text' : 'password'
      // this.eyeb = false;
      // this.eyeb_no = true;
      // this.pwd = "password";
    },
    // eyea_no() {
    //   this.eyeb = true;
    //   this.eyeb_no = false;
    //   this.pwd = "type";
    // },
    // 红色的按钮
    red_btn () {
      if (
        /^1[3456789]\d{9}$/.test(this.phone) &&
        this.codea.length == 4 &&
        /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.password) &&
        this.password == this.password_2 &&
        this.phone1.length == 18
      ) {
        this.checked_no = false
      } else {
        this.checked_no = true
      }
    },

    keyu () {
      this.red_btn()
    },
    // 密码1失焦
    blu_2 () {
      if (this.password != '') {
        if (
          !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.password)
        ) {
          this.pwd_2 = true
          this.pwd_2_ok = false
        } else {
          this.pwd_2 = false
          this.pwd_2_ok = true
        }
      }
    },
    // 密码1聚焦
    focu_2 () {
      this.pwd_2 = false
      this.pwd_2_ok = false
    },
    // 密码2聚焦
    focu_3 () {
      this.pwd_3 = false
      this.pwd_3_ok = false
    },
    blu_3 () {
      if (this.password_2 != '') {
        if (this.checked_no == false) {
          this.pwd_3 = false
          this.pwd_3_ok = true
        } else {
          this.pwd_3 = true
          this.pwd_3_ok = false
        }
      }
    },
    // 修改按钮
    forget () {
      this.forget_ent_acct_pwd_a()
    },
    // 修改密码接口
    forget_ent_acct_pwd_a () {
      forget_ent_acct_pwd({
        data: {
          mobile: this.phone,
          pwd: this.password,
          sms: this.codea,
          ent_acct: this.phone1
        }
      }).then(res => {
        if (res.data.code == 10013) {
          this.$message({
            message: '修改成功，去登录',
            type: 'success'
          })
          sessionStorage.setItem('pc_mlbb_phone', JSON.stringify(this.phone1))
        //   this.$router.push('/login')
        } else if (res.data.code == 10014) {
          this.$message({
            message: '修改密码失败',
            type: 'error'
          })
        } else if (res.data.code == 10005) {
          this.$message({
            message: '验证码错误',
            type: 'warning'
          })
        }
      })
    },
    wuyong () {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fgtrgs {
  background: #E7E8EB;
  min-height: 100vh;
  .content {
    height: 100%;
    width: 100%;
    padding-top:1.83rem;
    margin-bottom:0.99rem;
    .from {
      height: 5.44rem;
      width: 12rem;
      margin: 0 auto;
      background-color: #fff;
      box-shadow: 0 0.03rem 0.25rem 0 rgba(0, 0, 0, 0.15);
      .titled {
        height: 0.75rem;
        background: #f5f5f5;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .p_1 {
          font-size: 0.22rem;
          color: #676669;
          line-height: 0.75rem;
          margin-left: 0.49rem;
        }
        .p_2 {
          font-size: 0.16rem;
          color: #615f62;
          line-height: 0.75rem;
          margin-right: 0.5rem;
          .log_a {
            font-size: 0.16rem;
            color: #3494ff;
          }
        }
      }
      .from_a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.69rem;
        position: relative;
        ul{
          li{
            display: flex;
            align-items: center;
            margin-bottom: 0.21rem;
            p{
              &:nth-child(1){
                width:1.4rem;
                font-size: 0.16rem;
                color: #676669;
                text-align: right;
                margin-right: 0.1rem;
              }
            }
            &:nth-child(2),&:nth-child(4),&:nth-child(5){
              position: relative;
              img{
                &:nth-child(3){
                  position: absolute;
                  left:1.65rem;
                  height:0.2rem;
                  width:0.2rem;
                }
              }
            }
            input{
              height: 0.35rem;
              width: 2.61rem;
              border: 0.01rem solid #ddd;
              border-radius: 0.06rem;
              padding-left: 0.45rem;
            }
            &:nth-child(3){
              input{
                width: 1.55rem;
              }
              .code{
                height: 0.35rem;
                width: 1rem;
                background-color: #e9e9e9;
                color: #615f62;
                text-align: center;
                line-height: 0.38rem;
                border-radius: 0.06rem;
                margin-left: 0.05rem;
                font-size: 0.14rem;
                cursor: pointer;
              }
            }
            &:nth-child(4){
              position: relative;
              img{
                &:nth-child(4){
                  padding: 0.06rem 0.2rem;
                  border-left: 0.01rem solid #ddd;
                  position: absolute;
                  height: 0.16rem;
                  width: 0.2rem;
                  right: 0.01rem;
                  cursor: pointer;
                }
              }
            }
            &:nth-child(6){
              p{
                &:nth-child(2){
                  height: 0.38rem;
                  width: 3.05rem;
                  background-color: #B8C1CE;
                  line-height: 0.38rem;
                  text-align: center;
                  border-radius: 0.06rem;
                  color: #fff;
                  box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.5);
                  font-size: 0.16rem;
                  cursor: pointer;
                }
              }
            }
          }
        }
        // .ul_left {
        //   margin-top: -0.6rem;
        //   li {
        //     font-size: 0.16rem;
        //     color: #676669;
        //     text-align: right;
        //     padding: 0.19rem 0.14rem;
        //   }
        // }
        // .ul_right {
        //   li {
        //     input {
        //       height: 0.35rem;
        //       width: 2.61rem;
        //       border: 0.01rem solid #ddd;
        //       border-radius: 0.06rem;
        //       margin-bottom: 0.21rem;
        //       padding-left: 0.45rem;
        //     }
        //     .coded {
        //       width: 1.55rem;
        //     }
        //     .log {
        //       display: block;
        //       height: 0.38rem;
        //       width: 2.85rem;
        //       // background: -webkit-linear-gradient(
        //       //   left,
        //       //   #e65050,
        //       //   #fa6f6f
        //       // ); /* Safari 5.1 - 6.0 */
        //       // background: -o-linear-gradient(
        //       //   right,
        //       //   #e65050,
        //       //   #fa6f6f
        //       // ); /* Opera 11.1 - 12.0 */
        //       // background: -moz-linear-gradient(
        //       //   right,
        //       //   #e65050,
        //       //   #fa6f6f
        //       // ); /* Firefox 3.6 - 15 */
        //       // background: linear-gradient(to right, #e65050, #fa6f6f); /* 标准的语法 */
        //       background: #8d52e3;
        //       line-height: 0.38rem;
        //       text-align: center;
        //       border-radius: 0.06rem;
        //       color: #fff;
        //       box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.5);
        //       font-size: 0.16rem;
        //     }
        //     .log_b {
        //       display: block;
        //       height: 0.38rem;
        //       width: 3.05rem;
        //       background-color: #B8C1CE;
        //       line-height: 0.38rem;
        //       text-align: center;
        //       border-radius: 0.06rem;
        //       color: #fff;
        //       box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.5);
        //       font-size: 0.16rem;
        //     }
        //           }
        //           .li_c {
        //             display: flex;
        //             flex-wrap: wrap;
        //             .code {
        //               height: 0.38rem;
        //               width: 1rem;
        //               background-color: #e9e9e9;
        //               color: #615f62;
        //               text-align: center;
        //               line-height: 0.38rem;
        //               border-radius: 0.06rem;
        //               margin-left: 0.05rem;
        //               font-size: 0.14rem;
        //             }
        //           }
        //         }
        //         .img_a {
        //           position: absolute;
        //           top: 1.35rem;
        //           left: 5.25rem;
        //           height: 0.2rem;
        //           width: 0.2rem;
        //         }
        //         .img_b {
        //           position: absolute;
        //           top: 2.5rem;
        //           left: 5.25rem;
        //           height: 0.2rem;
        //           width: 0.2rem;
        //         }
        //         .img_c {
        //           position: absolute;
        //           top: 3.1rem;
        //           left:5.25rem;
        //           height: 0.2rem;
        //           width: 0.2rem;
        //         }
        //         .eye_box {
        //           position: absolute;
        //           top: 2.55rem;
        //           left: 7.55rem;
        //           padding-left: 0.1rem;
        //           border-left: 0.01rem solid #ddd;
        //           .eye {
        //             height: 0.16rem;
        //             width: 0.2rem;
        //           }
        //         }
        //         .regb {
        //           position: absolute;
        //           right: 2.9rem;
        //           top: 2.76rem;
        //         }
        //         .regc {
        //           position: absolute;
        //           right: 2.9rem;
        //           top: 3.08rem;
        //         }
        //       }
        //     }
            }
          }
        }
      }
@media screen and (max-width: 12rem) {
  .fgtrgs {
    width:12rem;
  }
}
</style>
